// Times in milliseconds
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;

export interface FormatRelativeTimeOptions {
  locale?: string;
  minimumUnit?: 'week' | 'day' | 'hour' | 'minute' | 'second';
  formatOptions?: Intl.RelativeTimeFormatOptions;
  humanize?: boolean;
}

export function formatRelativeTime(
  date: Date | string,
  options: FormatRelativeTimeOptions = {},
) {
  const {
    locale = 'en-US',
    minimumUnit = 'second',
    formatOptions = { style: 'long' },
    humanize = true,
  } = options;
  const formatter = new Intl.RelativeTimeFormat(locale, formatOptions);
  const epoch = (typeof date === 'string' ? new Date(date) : date).getTime();

  const diff = epoch - new Date().getTime();

  // eslint-disable-next-line ts/switch-exhaustiveness-check
  switch (true) {
    case Math.abs(diff) > WEEK || minimumUnit === 'week':
      return formatter.format(Math.trunc(diff / WEEK), 'week');

    case Math.abs(diff) > DAY || minimumUnit === 'day':
      return formatter.format(Math.trunc(diff / DAY), 'day');

    case Math.abs(diff) > HOUR || minimumUnit === 'hour':
      return formatter.format(Math.trunc((diff % DAY) / HOUR), 'hour');

    case Math.abs(diff) > MINUTE || minimumUnit === 'minute':
      return formatter.format(Math.trunc((diff % HOUR) / MINUTE), 'minute');

    default:
      return humanize
        ? 'Just now'
        : formatter.format(Math.trunc((diff % MINUTE) / SECOND), 'second');
  }
}
