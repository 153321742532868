const formatter = new Intl.RelativeTimeFormat('en-US', {
  style: 'long',
  numeric: 'always',
});
function format(...args: Parameters<Intl.RelativeTimeFormat['format']>) {
  return formatter
    .format(...args)
    .replace('in', '')
    .trim();
}

export function formatTimePeriod(
  from: Date,
  to: Date,
  minUnit: 'month' | 'year' = 'month',
) {
  // Calculate the difference in months
  const diff = (to.getFullYear() - from.getFullYear()) * 12 + (to.getMonth() - from.getMonth());
  const years = Math.floor(diff / 12);
  const months = diff % 12;

  if (minUnit === 'year') {
    return format(years, 'year');
  }
  else {
    const parts = [];
    if (years > 0)
      parts.push(format(years, 'year'));
    if (months > 0 || years === 0)
      parts.push(format(months, 'month'));
    return parts.join(', ');
  }
}
