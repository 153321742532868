'use client';

import type { ChangeEvent, ComponentProps, CSSProperties } from 'react';
import clsx from 'clsx';

import RangeTouch from 'rangetouch';
import { useCallback, useEffect, useId, useMemo, useState } from 'react';

export type RangeInputProps = Pick<ComponentProps<'input'>, 'onChange'> & {
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
};

export function RangeInput(props: RangeInputProps) {
  const {
    min = 0,
    max = 100,
    step = 1,
    value: valueProp = 50,
    onChange,
    className,
  } = props;
  const id = useId();
  const [value, setValue] = useState<number>(valueProp);
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const percentage = useMemo(
    () => ((value - min) / (max - min)) * 100,
    [max, min, value],
  );
  // @ts-expect-error -- types don't account for custom properties
  const style: CSSProperties = { '--value': `${percentage}%` };

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(Number(event.target.value));
      onChange?.(event);
    },
    [onChange],
  );

  useEffect(() => {
    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
    setValue(valueProp);
  }, [valueProp]);

  useEffect(() => {
    if (!ref)
      return;
    // eslint-disable-next-line ts/no-unsafe-assignment, ts/no-unsafe-call
    const rangetouch = new RangeTouch(ref);
    // eslint-disable-next-line ts/no-unsafe-return, ts/no-unsafe-call, ts/no-unsafe-member-access
    return () => rangetouch.destroy();
  }, [ref]);

  return (
    <>
      <label htmlFor={id} className="sr-only">
        Example
      </label>

      <input
        ref={setRef}
        type="range"
        id={id}
        className={clsx('input-range block', className)}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChangeHandler}
        onInput={onChangeHandler}
        style={style}
      />
    </>
  );
}
