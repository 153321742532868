export type TimeUnit =
  | 'year'
  | 'month'
  | 'week'
  | 'day'
  | 'hour'
  | 'minute'
  | 'second';

export interface FormatDateRangeOptions {
  locale?: string;
  minimumUnit?: TimeUnit;
  formatOptions?: Intl.RelativeTimeFormatOptions;
}

export interface FormatDateRangeResponse {
  formatted: string;
  unit: TimeUnit;
}

// Times in milliseconds
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const YEAR = 365 * DAY;

export function formatDateRange(
  from: Date,
  to: Date,
  options?: FormatDateRangeOptions,
) {
  const {
    locale = 'en-US',
    minimumUnit = 'second',
    formatOptions = { style: 'long' },
  } = options ?? {};
  const formatter = new Intl.RelativeTimeFormat(locale, formatOptions);
  const diff = to.getTime() - from.getTime();

  // eslint-disable-next-line ts/switch-exhaustiveness-check
  switch (true) {
    case Math.abs(diff) > YEAR || minimumUnit === 'year':
      return formatter.format(Math.trunc(diff / YEAR), 'year');

      /* case Math.abs(diff) > WEEK || minimumUnit === 'week':
      return formatter.format(Math.trunc(diff / WEEK), 'week'); */

    case Math.abs(diff) > WEEK || minimumUnit === 'week':
      return formatter.format(Math.trunc(diff / WEEK), 'week');

    case Math.abs(diff) > DAY || minimumUnit === 'day':
      return formatter.format(Math.trunc(diff / DAY), 'day');

    case Math.abs(diff) > HOUR || minimumUnit === 'hour':
      return formatter.format(Math.trunc((diff % DAY) / HOUR), 'hour');

    case Math.abs(diff) > MINUTE || minimumUnit === 'minute':
      return formatter.format(Math.trunc((diff % HOUR) / MINUTE), 'minute');

    default:
      return formatter.format(Math.trunc((diff % MINUTE) / SECOND), 'second');
  }
}
