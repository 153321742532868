'use client';

/* eslint-disable jsx-a11y/media-has-caption */

import type Plyr from 'plyr';

import type { CSSProperties, VideoHTMLAttributes } from 'react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import 'plyr/dist/plyr.css';

type VideoProps = VideoHTMLAttributes<HTMLVideoElement> & {
  thumbnails?: string[];
};

export function Video(props: VideoProps) {
  const {
    className,
    poster,
    playsInline = true,
    controls = true,
    crossOrigin = 'anonymous',
    thumbnails,
    muted,
    ...rest
  } = props;
  const [ref, setRef] = useState<HTMLVideoElement | null>(null);
  const playerRef = useRef<Plyr>(undefined);

  useEffect(() => {
    if (ref && typeof window !== 'undefined') {
      void import('plyr').then(({ default: Plyr }) => {
        playerRef.current = new Plyr(ref, {
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            !muted ? 'mute' : '',
            // 'volume',
            // 'captions',
            // 'settings',
            // 'pip',
            // 'airplay',
            'fullscreen',
          ].filter(Boolean),
          previewThumbnails: {
            enabled: Boolean(thumbnails?.length),
            src: thumbnails,
          },
        });
      });
    }
  }, [muted, ref, thumbnails]);

  return (
    <div
      className={clsx(
        'not-prose max-w-full light:after:border-b-0 relative after:pointer-events-none after:absolute after:inset-0 after:z-10 after:rounded-[inherit] after:border after:border-black/10 dark:after:border-white/10',
        'before:pointer-events-none before:absolute before:inset-px before:z-10 before:rounded-[inherit] before:shadow-light-top before:shadow-white/10 dark:before:hidden',
        className,
      )}
      style={{ '--plyr-color-main': 'var(--color-orange-500)' } as CSSProperties}
    >
      <video
        ref={setRef}
        controls={controls}
        crossOrigin={crossOrigin}
        playsInline={playsInline}
        poster={poster}
        className="rounded-[inherit]"
        {...rest}
      />
    </div>
  );
}
